export default {
    lessons: {
      loading: true,
      error: null,
      data: {},
      isSearchActive: false,
      foundlessons: [],
      lessonDetails: null,
    },
    addlessons: {
      loading: false,
      error: null,
      data: null,
    },
  };
  