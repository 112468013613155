export default {
    news: {
      newsLoading: false,
      newsData: null,
      tipsData: null,
      newsError: null
    },

    theme: {
        themeLoading: false,
        theme: 'Dark'
      },

    stats: {
      statLoading: false,
      statData: [],
      statError: null
    }
  };
  