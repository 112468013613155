export const REGISTER_LOADING = "REGISTER_LOADING";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_ERROR = "REGISTER_ERROR";
export const LOGIN_LOADING = "LOGIN_LOADING";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const FORGOTPASS_LOADING = "LOGIN_LOADING";
export const FORGOTPASS_SUCCESS = "LOGIN_SUCCESS";
export const FORGOTPASS_ERROR = "LOGIN_ERROR";

export const KYC_SUCCESS = "KYC_SUCCESS";

export const VERIFIED = "VERIFIED";

export const COURSE_LOADING = "COURSE_LOADING";
export const COURSE_LOAD_SUCCESS = "COURSE_LOAD_SUCCESS";
export const COURSE_LOAD_DETAILS = "COURSE_LOAD_DETAILS";
export const COURSE_LOAD_ERROR = "COURSE_LOAD_ERROR";
export const ENROLL_COURSE_LOADING = "ENROLL_COURSE_LOADING";
export const COURSE_ENROLL_SUCCESS = "COURSE_ENROLL_SUCCESS";
export const COURSE_ENROLL_ERROR = "COURSE_ENROLL_ERROR";
export const MY_COURSE_LOAD_SUCCESS = "MY_COURSE_LOAD_SUCCESS";
export const LOGOUT_USER = "LOGOUT_USER";


export const UPDATE_LOADING = "UPDATE_LOADING";
export const UPDATE_SUCCESS = "UPDATE_SUCCESS";
export const UPDATE_ERROR = "UPDATE_ERROR";

export const LESSON_LOADING = 'LESSON_LOADING';
export const LESSON_LOAD_SUCCESS = 'LESSON_LOAD_SUCCESS';
export const LESSON_LOAD_ERROR = 'LESSON_LOAD_ERROR';

export const ADD_COURSE_LOAD = "ADD_COURSE_LOAD";
export const ADD_COURSE_SUCCESS = "ADD_COURSE_SUCCESS";
export const ADD_COURSE_ERROR = "ADD_COURSE_ERROR";
export const CLEAR_ADD_COURSE = "CLEAR_ADD_COURSE";
export const SEARCH_COURSES = "SEARCH_COURSE";
export const DELETE_COURSE_LOADING = "DELETE_COURSE_LOADING";
export const DELETE_COURSE_SUCCESS = "DELETE_COURSE_SUCCESS";
export const DELETE_COURSE_ERROR = "DELETE_COURSE_ERROR";
export const ADD_REMOVE_STAR_LOADING = "ADD_REMOVE_STAR_LOADING";
export const ADD_REMOVE_STAR_ERROR = "ADD_REMOVE_STAR_ERROR";
export const ADD_REMOVE_STAR_SUCCESS = "ADD_REMOVE_STAR_SUCCESS";

export const QUIZ_LOADING = "QUIZ_LOADING";
export const QUIZ_LOAD_SUCCESS = "QUIZ_LOAD_SUCCESS";
export const QUIZ_LOAD_ERROR = "QUIZ_LOAD_ERROR";
export const QUIZ_ANSWER_LOADING = "QUIZ_ANSWER_LOADING";
export const QUIZ_ATTEMPT_LOAD = "QUIZ_ATTEMPT_LOAD";
export const QUIZ_ATTEMPT_ERROR = "QUIZ_ATTEMPT_ERROR";
export const QUIZ_END = "QUIZ_END";

export const TAKEN_LOADING = "TAKEN_LOADING";
export const TAKEN_LOAD_SUCCESS = "TAKEN_LOAD_SUCCESS";
export const TAKEN_LOAD_ERROR = "TAKEN_LOAD_ERROR";

export const ATTEMPT_ERROR_HIDE = "ATTEMPT_ERROR_HIDE";

export const QUESTION_LOADING = "QUESTION_LOADING";
export const QUESTION_LOAD_SUCCESS = "QUESTION_LOAD_SUCCESS";
export const QUESTION_LOAD_ERROR = "QUESTION_LOAD_ERROR";


export const WITHDRAW_LOADING = "WITHDRAW_LOADING";
export const WITHDRAW_LOAD_SUCCESS = "WITHDRAW_LOAD_SUCCESS";
export const WITHDRAW_LOAD_ERROR = "WITHDRAW_LOAD_ERROR";

export const CRYPTO_LOADING = "CRYPTO_LOADING";
export const CRYPTO_LOAD_SUCCESS = "CRYPTO_LOAD_SUCCESS";
export const CRYPTO_LOAD_ERROR = "CRYPTO_LOAD_ERROR";

export const USER_REF_LOADING = "USER_REF_LOADING";
export const USER_REF_SUCCESS = "USER_REF_SUCCESS";
export const USER_REF_ERROR = "USER_REF_ERROR";

export const USER_TRASACTION_LOADING = "USER_TRASACTION_LOADING";
export const USER_TRASACTION_SUCCESS = "USER_TRASACTION_SUCCESS";
export const USER_TRASACTION_ERROR = "USER_TRASACTION_ERROR";

export const NEWS_LOADING = "NEWS_LOADING";
export const NEWS_LOAD_SUCCESS = "NEWS_LOAD_SUCCESS";
export const NEWS_LOAD_ERROR = "NEWS_LOAD_ERROR";

export const MARKET_STATS_LOADING = "MARKET_STATS_LOADING";
export const MARKET_STATS_SUCCESS = "MARKET_STATS_SUCCESS";
export const MARKET_STATS_ERROR = "MARKET_STATS_ERROR";

export const STATS_LOADING = "STATS_LOADING";
export const STATS_LOAD_SUCCESS = "STATS_LOAD_SUCCESS";
export const STATS_LOAD_ERROR = "STATS_LOAD_ERROR";
