export default {
  courses: {
    loading: true,
    error: null,
    data: [],
    isSearchActive: false,
    foundCourses: [],
    courseDetails: {},
    enrolled: false,
    enrolling: false,
    enrollError: false,
    mycourse:[]

  },
  addCourses: {
    loading: false,
    error: null,
    data: null,
  },
};
