import {
    NEWS_LOADING,
    NEWS_LOAD_SUCCESS,
    NEWS_LOAD_ERROR,
    STATS_LOADING,
    STATS_LOAD_SUCCESS,
    STATS_LOAD_ERROR,
} from "../../constants/actionTypes";
  
  const app = (state, { payload, type }) => {
    switch (type) {
      case NEWS_LOADING:
        return {
          ...state,
          news: {
            ...state.news,
            newsLoading: true
        }
        };
  
      case NEWS_LOAD_SUCCESS:
        return {
            ...state,
            news: {
                ...state.news,
                newsLoading: false,
                newsData: payload.news,
                tipsData: payload.tips
            }
          };
      
      case NEWS_LOAD_ERROR:
        return {
            ...state,
            news: {
                ...state.news,
                newsLoading: false,
                newsError: payload
            }
          };

          case STATS_LOADING:
        return {
          ...state,
          stats: {
            ...state.stats,
            statLoading: true
        }
        };
  
      case STATS_LOAD_SUCCESS:
        return {
            ...state,
            stats: {
                ...state.stats,
                statLoading: false,
                statData: payload
            }
          };
      
      case STATS_LOAD_ERROR:
        return {
            ...state,
            stats: {
                ...state.stats,
                statLoading: false,
                statError: payload
            }
          };

      default:
        return state;
    }
  };
  
  export default app;
  