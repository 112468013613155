import React, {  
    useContext,
    useEffect,
    useState
  } from "react";
  import jwt_decode from 'jwt-decode';

import { refresh_token } from "../context/actions/user/refresh_token";
import { GlobalContext } from '../context/provider';
import isAuthenticated   from "./isAuthenticated";
let refreshInterval;
let Refresh = () => {
  
  const [intervalId, setIntervalId] = useState(null);
    const {
        authState : { 
            auth: {token
      }}} = useContext(GlobalContext);

      const refreshMethod = () => {
        console.log('read');
        let tokenFunc =  token || localStorage.token;
        if(tokenFunc){
          let jwt = jwt_decode(tokenFunc, {headers: true});
          let current_time = Date.now().valueOf();
          const jwtExp = jwt.exp - current_time ;
          console.log(jwtExp);
          console.log(jwtExp < 200000);

          if(jwtExp < 200000){
            refresh_token();
          }
        }
      }
    
      useEffect(() => {
        if(token || localStorage.token){
          if(!refreshInterval){
            refreshInterval = setInterval(refresh_token(), 120000)
          }
        }else{ 
          if(refreshInterval){
            clearInterval(refreshInterval);
            refreshInterval = null;
          }
        }
      },[token])

      console.log("interval", refreshInterval);

      return null;
}

export default Refresh;