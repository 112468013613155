export default {
    referal: {
      refloading: false,
      refData: null,
      referror: null
    },

    withdraw: {
      wloading: false,
      wData: null,
      werror: null
    },

    transaction: {
        transactionloading: false,
        transactionData: [],
        transactionerror: null
      }
  };
  