import {
    CRYPTO_LOADING,
    CRYPTO_LOAD_SUCCESS,
    CRYPTO_LOAD_ERROR
} from "../../constants/actionTypes";

  import cryptoInitialState from "../initialStates/cryptoInitialState";
  
  const crypto = (state, { payload, type }) => {
    switch (type) {
      case CRYPTO_LOADING: {
        return {
          ...state,
          crypto: {
            ...state.crypto,
            loading: true,
          },
        };
      }
  
      case CRYPTO_LOAD_SUCCESS: {
          
        return {
          ...state,
          crypto: {
            ...state.crypto,
            loading: false,
            cryptos: payload,
          },
        };
      }

      case CRYPTO_LOAD_ERROR: {
        return {
          ...state,
          crypto: {
            ...state.crypto,
            loading: false,
            error: payload,
          },
        };
      }
      
      default:
        return state;
    }
  };
  
  export default crypto;
  