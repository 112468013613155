//--------------------------|| MODULE DEPENDENCIES ||-------------------------------//

import React, { lazy } from "react";


//--------------------------||      COMPONENTS     ||-------------------------------//

const AllAssetsComponent          = lazy(() => import("../pages/AllAssets/"));
const AssetComponent              = lazy(() => import("../pages/Assets/Assets"));
const CourseDetailComponent       = lazy(() => import("../pages/CourseDetails/courseDetail"));
const FAQComponent                = lazy(() => import("../pages/Faq"));
const KycDoneComponent            = lazy(() => import("../pages/kycVerify/done"));
// const PrivacyComponent            = lazy(() => import("../pages/PrivacyPolicy"));
// const TermsOfServiceComponent     = lazy(() => import("../pages/TermsOfService"));
const TokenComponent              = lazy(() => import("../pages/Token"));
const AboutComponent              =  lazy(() => import('../pages/AboutUs'));
const EarnathonPro                = lazy(() => import("../pages/EarnathonPro"));


const  HomeComponent              = lazy(() => import("../containers/Home/"));
const NewHomeComponent            = lazy(() => import("../pages/NewLanding"));
const LessonContainer             = lazy(() => import("../containers/Lesson/"));


const DashboardComponent          = lazy(() => import("../containers/Dashboard/"));
const RegisterComponent           = lazy(() => import("../containers/Register/"));
const LoginComponent              = lazy(() => import("../containers/Login/"));
const ForgottenPasswordComponent  = lazy(() => import("../containers/ForgotPassword/"));
const ResetPasswordComponent      = lazy(() => import("../containers/ResetPassword/"));
const OtpComponent                = lazy(() => import("../containers/Otp/"));
const KycOtpComponent             = lazy(() => import("../containers/KycOtp/"));
const UserUpdateDetailsComponent  = lazy(() => import("../containers/User/update"));
const SettingsComponent           = lazy(() => import("../containers/Settings/"));
const QuizComponent               = lazy(() => import("../containers/Quiz/index"));
const TaskComponent               = lazy(() => import("../containers/Task/"));

const EmailVerifyContainer        = lazy(() => import("../containers/Verify/"));
const NotFoundComponent           = lazy(() => import("../components/NotFound"));
const MaintainanceFoundComponent  = lazy(() => import("../components/Maintainance"));
const ResetSentComponent          = lazy(() => import("../components/Authentication/resetSent"));
const EmailSentComponent          = lazy(() => import("../components/Authentication/emailSent"));
const TaskCompletedComponent       = lazy(() => import("../components/Authentication/taskCompleted"));
const routes = [

  {
    path: "/",
    component: HomeComponent ,
    title: "Learn and Earn with Earnathon ",
    needsAuth: 'static'
  },

  {
    path: "/",
    component: HomeComponent,
    title: "Learn and Earn with Earnathon ",
    needsAuth: 'static'
  },
  {
    path: "/:course/task",
    component: TaskComponent ,
    title: "Perform tasks and earn || Earnathon.com",
    needsAuth: true
  },

  {
    path: "/home",
    component: DashboardComponent,
    title: "Dashboard | Earnathon",
    needsAuth: true
  },
 

//--------------------------||     AUTH ROUTES START     ||-------------------------------//
  {
    path: "/signup",
    component: RegisterComponent,
    title: "Create Account | Earnathon",
    needsAuth: false
  },

  {
    path: "/login",
    component: LoginComponent,
    title: "Login | Earnathon ",
    needsAuth: false
  },

  {
    path: "/forgot",
    component: ForgottenPasswordComponent,
    title: "Forgot Password",
    needsAuth: false
  },

  {
    path: "/otp",
    component: OtpComponent,
    title: "Confirm OTP | Earnathon",
    needsAuth: false
  },

  {
    path: "/reset",
    component: ResetPasswordComponent,
    title: "Reset Password | Earnathon",
    needsAuth: false
  },

  {
    path: "/update-account-info",
    component: UserUpdateDetailsComponent,
    title: "Update Account | Earnathon",
    needsAuth: true
  },

  { 
    path: '/settings', 
    component: SettingsComponent,
    title: "Settings | Earnathon",
    needsAuth: true
  },

  { 
    path: '/lesson/:name', 
    component: CourseDetailComponent,
    title: "Courses | Learn and Earn",
    needsAuth: 'static'
  },

  { 
    path: '/course/:name/lesson/:lesson', 
    component: LessonContainer,
    title: "Learn Lessons | Earnathon",
    needsAuth: 'static'
  },

  { 
    path: '/token', 
    component: TokenComponent,
    title: "ENA Token | Earnathon", 
    needsAuth: 'static'
  },

  { 
    path: '/faq', 
    component: FAQComponent,
    title: "FAQ | Earnathon",
    needsAuth: 'static'
  },

  { 
    path: '/quiz/:lesson_id', 
    component: QuizComponent,
    title: "Quiz | Earnathon",
    needsAuth: true
  },

  // {
  //   path: '/earnathon-pro',
  //   component: EarnathonPro,
  //   title: "Earnthon Pro | Earnathon",
  //   needsAuth: 'static' 
  // },

// //--------------------------||     ASSETS ROUTES START     ||-------------------------------//

  {
    path: "/explore",
    component: AllAssetsComponent,
    title: "Explore | Earnathon",
    needsAuth: 'static'
  },

  {
    path: "/about",
    component: AboutComponent,
    title: "About | Earnathon",
    needsAuth: 'static'
  },

  {
    path: "/assets/:coin",
    component: AssetComponent,
    title: "Assets Details | Earnathon",
    needsAuth: 'static'
  },
  
  //--------------------------|| MESSAGE UX ROUTES START     ||-------------------------------//

  {
    path: "/email-sent",
    component: EmailSentComponent,
    title: "Email success | Earnathon ",
    needsAuth: false
  },

  {
    path: "/reset-sent",
    component: ResetSentComponent,
    title: "Reset sent | Earnathon",
    needsAuth: false
  },

  {
    path: "/task-completed",
    component: TaskCompletedComponent,
    title: "Task Completed | Earnathon",
    needsAuth: 'static'
  },

  {
    path: "/verify/:token",
    component: EmailVerifyContainer,
    title: "Verify Email | Earnathon",
    needsAuth: false
  },

  {
    path: "/kyc-verify",
    component: KycOtpComponent,
    title: "Profile Verification | Earnathon",
    needsAuth: true
  },

  {
    path: "/kyc-done",
    component: KycDoneComponent,
    title: "Profile Verification Done | Earnathon",
    needsAuth: true
  },

  // --------------------------|| CASUAL ROUTES START     ||-------------------------------//

  {
    path: "*", //for some reason adding * makes it default all pages
    component: NotFoundComponent,
    title: "404 | Earnathon",
    needsAuth: 'static'
  },
  //  when ever we need to be on maintainance
  // {
  //   path: "*", //for some reason adding * makes it default all pages
  //   component: MaintainanceFoundComponent,
  //   title: "maintainance | Earnathon",
  //   needsAuth: 'static'
  // },

];

export default routes;