import axiosInstance from "../../../utils/axiosInstance";
import { CONNECTION_ERROR } from "../../../constants/api";
import DeltastackCrossOriginCommunicator from '../../../utils/deltastackCrossOriginCommunicator';
const initCommunication = async (data) => {
  const origin = '*';
  if (typeof window !== 'undefined') {
    const enaCors = new DeltastackCrossOriginCommunicator(origin,false);
    enaCors.setLocalStorage({
      key: 'token',
      value: data
    })

  }
}
export const refresh_token = () => (dispatch) => {

  axiosInstance()
    .post(`user/refresh-token`)
    .then((res) => {

      let {status, message, data} = res.data;

      if(status){
        // initCommunication(data.token);
        window.parent.postMessage({
          type: 'authToken',
          value: data.token}, '*')
        localStorage.setItem('token', data.token);     
      }else{
         console.log(message);
      }
    })
    .catch((err) => {
        console.log(err);
    });
};
