import {
    USER_REF_LOADING,
    USER_REF_SUCCESS,
    USER_REF_ERROR,
    USER_TRASACTION_LOADING,
    USER_TRASACTION_SUCCESS,
    USER_TRASACTION_ERROR,
    WITHDRAW_LOADING,
    WITHDRAW_LOAD_SUCCESS,
    WITHDRAW_LOAD_ERROR,
} from "../../constants/actionTypes";
  
  const user = (state, { payload, type }) => {
    switch (type) {
      
      case USER_REF_LOADING:
        return {
          ...state,
          referal: {
            ...state.referal,
            refloading: true
        }
        };
  
      case USER_REF_SUCCESS:
        return {
            ...state,
            referal: {
                ...state.referal,
                refloading: false,
                refData: payload
            }
          };
      
      case USER_REF_ERROR:
        return {
            ...state,
            referal: {
                ...state.referal,
                refloading: false,
                referror: payload
            }
          };


     case USER_TRASACTION_LOADING:
        return {
          ...state,
          transaction: {
            ...state.referal,
            transactionloading: true
        }
        };
  
      case USER_TRASACTION_SUCCESS:
        return {
            ...state,
            transaction: {
                ...state.referal,
                transactionloading: false,
                transactionData: payload
            }
          };
      
      case USER_TRASACTION_ERROR:
        return {
            ...state,
            transaction: {
                ...state.referal,
                transactionloading: false,
                transactionerror: payload
            }
          };

      case WITHDRAW_LOADING:
        return {
          ...state,
          withdraw: {
              ...state.withdraw,
              wloading: true,
              wData: null,
              werror: null,

          }
        };

      case WITHDRAW_LOAD_SUCCESS:
        return {
          ...state,
          withdraw: {
              ...state.withdraw,
              wloading: false,
              wData: payload
          }
        };

      case WITHDRAW_LOAD_ERROR:
        return {
          ...state,
          withdraw: {
              ...state.withdraw,
              wloading: false,
              werror: payload
          }
        };

      default:
        return state;
    }
  };
  
  export default user;
  