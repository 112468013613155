import {
    QUIZ_LOADING,
    QUIZ_LOAD_SUCCESS,
    QUIZ_LOAD_ERROR,
    QUESTION_LOADING,
    QUESTION_LOAD_SUCCESS,
    QUESTION_LOAD_ERROR,
    QUIZ_ANSWER_LOADING,
    QUIZ_ATTEMPT_LOAD,
    QUIZ_ATTEMPT_ERROR,
    ATTEMPT_ERROR_HIDE,
    QUIZ_END,
    TAKEN_LOADING,
    TAKEN_LOAD_SUCCESS,
    TAKEN_LOAD_ERROR,
} from "../../constants/actionTypes";

  import quizInitialState from "../initialStates/lessonsInitialState";
  
  const quiz = (state, { payload, type }) => {
    switch (type) {
      case TAKEN_LOADING: {
        return {
          ...state,
          quizes: {
            ...state.quizes,
            quiz:[],
            attempt: null,
            loading: true,
          },
          testQuiz: {
            ...state.testQuiz,
            testData: null,
            testLoading: true,
          },
        };
      }

      case TAKEN_LOAD_SUCCESS: {
    
        return {
          ...state,
          testQuiz: {
            ...state.testQuiz,
            testData: payload,
            testLoading: false
          },
        };
      }

      case TAKEN_LOAD_ERROR: {
        return {
          ...state,
          testQuiz: {
            ...state.testQuiz,
            testLoading: false,
            testError: payload,
          },
        };
      }

      case QUIZ_LOADING: {
        return {
          ...state,
          quizes: {
            ...state.quizes,
            quiz:[],
            showQuiz: null,
            loading: true,
          },
        };
      }

      case QUIZ_ANSWER_LOADING: {
        return {
          ...state,
          quizes: {
            ...state.quizes,
            attempt: false,
            answerLoading: true,
          },
        };
      }

      case QUIZ_ATTEMPT_LOAD: {
          
        return {
          ...state,
          quizes: {
            ...state.quizes,
            attempt: payload,
            answerLoading: false,
          },
        };
      }


      case QUIZ_END: {
          
        return {
          ...state,
          quizes: {
            ...state.quizes,
            quiz: [],
            attempt: false
          },

          testQuiz: {
            ...state.testQuiz,
            testData: true,
            testLoading: true,
          },
        };
      }

      case QUIZ_ATTEMPT_ERROR: {
          
        return {
          ...state,
          quizes: {
            ...state.quizes,
            attemptError: payload,
            answerLoading: false,
          },
        };
      }

      case ATTEMPT_ERROR_HIDE: {
          
        return {
          ...state,
          quizes: {
            ...state.quizes,
            attemptError: false,
          },
        };
      }
  
      case QUIZ_LOAD_SUCCESS: {
          
        return {
          ...state,
          quizes: {
            ...state.quizes,
            quiz: payload.data,
            showQuiz: payload.showQuiz,
            ended: payload.ended
          },
        };
      }

      case QUIZ_LOAD_ERROR: {
        return {
          ...state,
          quizes: {
            ...state.quizes,
            loading: false,
            error: payload,
          },
        };
      }

      case QUESTION_LOADING: {
        return {
          ...state,
          quizes: {
            ...state.quizes,
            loading: true,
          },
        };
      }
  
      case QUESTION_LOAD_SUCCESS: {
          
        return {
          ...state,
          quizes: {
            ...state.quizes,
            loading: false,
            questions: payload,
          },
        };
      }

      case QUESTION_LOAD_ERROR: {
        return {
          ...state,
          quizes: {
            ...state.quizes,
            loading: false,
            error: payload,
          },
        };
      }
      
      default:
        return state;
    }
  };
  
  export default quiz;
  