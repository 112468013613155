import {
    COURSE_LOADING,
    COURSE_LOAD_SUCCESS,
    COURSE_LOAD_ERROR,
    COURSE_LOAD_DETAILS,
    LOGOUT_USER,
    ADD_COURSE_LOAD,
    ADD_COURSE_SUCCESS,
    ADD_COURSE_ERROR,
    CLEAR_ADD_COURSE,
    SEARCH_COURSES,
    DELETE_COURSE_SUCCESS,
    DELETE_COURSE_LOADING,
    ADD_REMOVE_STAR_SUCCESS,
    ENROLL_COURSE_LOADING,
    COURSE_ENROLL_SUCCESS,
    COURSE_ENROLL_ERROR,
    MY_COURSE_LOAD_SUCCESS
} from "../../constants/actionTypes";

  import coursesInitialState from "../initialStates/coursesInitialState";
  
  const courses = (state, { payload, type }) => {
    switch (type) {
      case COURSE_LOADING: {
        return {
          ...state,
          courses: {
            ...state.courses,
            //courseDetails: null,
            error: null,
            loading: true,
          },
        };
      }
  
      case COURSE_LOAD_SUCCESS: {
        return {
          ...state,
          courses: {
            ...state.courses,
            loading: false,
            data: payload,
          },
        };
      }
      case COURSE_LOAD_ERROR: {
        return {
          ...state,
          courses: {
            ...state.courses,
            loading: false,
            error: payload,
          },
        };
      }

      case MY_COURSE_LOAD_SUCCESS: {
        return {
          ...state,
          courses: {
            ...state.courses,
            loading: false,
            mycourse: payload,
          },
        };
      }

      case ENROLL_COURSE_LOADING: {
        return {
          ...state,
          courses: {
            ...state.courses,
            enrolling: true,
          },
        };
      }



      case COURSE_ENROLL_SUCCESS: {
        return {
          ...state,
          courses: {
            ...state.courses,
            enrolling: false,
            enrolled: true,
            enrolled: true,
          },
        };
      }

      case COURSE_ENROLL_ERROR: {
        return {
          ...state,
          courses: {
            ...state.courses,
            enrolling: false,
            enrolled: true,
            enrollError: true,
          },
        };
      }

      case COURSE_LOAD_DETAILS: {
        let {data, id} = payload;
        return {
          ...state,
          courses: {
            ...state.courses,
            loading: false,
            courseDetails :  {
              ...state.courses.courseDetails,
              [id] : data
            },
          },
        };
      }
  
      case CLEAR_ADD_COURSE: {
        return {
          ...state,
          addCourses: {
            ...state.addCourses,
            error: null,
            loading: false,
            data: null,
          },
        };
      }
  
      case LOGOUT_USER: {
        return {
          ...state,
          coursesInitialState,
        };
      }
  
      case ADD_COURSE_LOAD: {
        return {
          ...state,
          addCourses: {
            ...state.addCourses,
            error: null,
            loading: true,
          },
        };
      }
  
      case ADD_COURSE_ERROR: {
        return {
          ...state,
          addCourses: {
            ...state.addCourses,
            loading: false,
          },
        };
      }
  
      case ADD_COURSE_SUCCESS: {
        return {
          ...state,
          addCourses: {
            ...state.addCourses,
            loading: false,
            data: payload,
          },
  
          courses: {
            ...state.courses,
            loading: false,
            data: [payload, ...state.courses.data],
          },
        };
      }
  
      case DELETE_COURSE_LOADING: {
        return {
          ...state,
          courses: {
            ...state.courses,
            loading: false,
            data: state.courses.data.map((item) => {
              if (item.id === payload) {
                return { ...item, deleting: true };
              }
              return item;
            }),
          },
        };
      }
  
      case ADD_REMOVE_STAR_SUCCESS: {
        return {
          ...state,
          courses: {
            ...state.courses,
            data: state.courses.data.map((item) => {
              if (item.id === payload.id) {
                return payload;
              }
              return item;
            }),
          },
        };
      }
  
      case DELETE_COURSE_SUCCESS: {
        return {
          ...state,
          courses: {
            ...state.courses,
            loading: false,
            data: state.courses.data.filter((item) => item.id !== payload),
          },
        };
      }
  
      case SEARCH_COURSES: {
        const searchValue = payload?.toLowerCase();
        return {
          ...state,
          courses: {
            ...state.courses,
            loading: false,
            isSearchActive: !!payload.length > 0 || false,
            foundcourses: state.courses.data.filter((item) => {
              try {
                return (
                  item.first_name.toLowerCase().search(searchValue) !== -1 ||
                  item.last_name.toLowerCase().search(searchValue) !== -1 ||
                  item.phone_number.toLowerCase().search(searchValue) !== -1
                );
              } catch (error) {
                return [];
              }
            }),
          },
        };
      }
      default:
        return state;
    }
  };
  
  export default courses;
  