export default {
    quizes: {
      loading: true,
      error: null,
      quiz: [],
      questions: [],
      quizDetails: null,
      questionDetails: null,
      answerLoading: false,
      attempt: false,
      attemptError: false,
      taken: null,
      showQuiz: null,
      ended: null,
    },
    testQuiz: {
      testLoading: true,
      testError: null,
      testData: true,
    },
  };
  