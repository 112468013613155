import {
    LESSON_LOADING,
    LESSON_LOAD_SUCCESS,
    LESSON_LOAD_ERROR
} from "../../constants/actionTypes";

  import lessonsInitialState from "../initialStates/lessonsInitialState";
  
  const lessons = (state, { payload, type }) => {
    
    switch (type) {
      case LESSON_LOADING: {
        return {
          ...state,
          lessons: {
            ...state.lessons,
            loading: true,
            //data:[]
          },
        };
      }
  
      case LESSON_LOAD_SUCCESS: {
        let {data, course} = payload;
        return {
          ...state,
          lessons: {
            ...state.lessons,
            loading: false,
            data: {
              ...state.lessons.data,
              [course] : data
            },
          },
        };
      }
      
      case LESSON_LOAD_ERROR: {
        return {
          ...state,
          lessons: {
            ...state.lessons,
            loading: false,
            error: payload,
          },
        };
      }

      default:
        return state;
    }
  };
  
  export default lessons;
  