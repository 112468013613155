export default {
  auth: {
    loading: false,
    data: null,
    error: null,
    success: false,
    kyc: null,
    token: null,
  },
};
