export const {
  NODE_ENV,
  REACT_APP_BACKEND_URL_PROD,
  REACT_APP_BACKEND_URL_DEV,
  REACT_APP_X_TOKEN,
  REACT_APP_SECRET_TOKEN,
  REACT_APP_DIGEST,
  REACT_APP_MAINTAINANCE,
} = process.env

export const maintainance = REACT_APP_MAINTAINANCE === 'on';
