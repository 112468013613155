import CookieConsent, { Cookies } from "react-cookie-consent";

import { Link } from "react-router-dom";

const App = () => (
    <>

      <CookieConsent
         buttonText="I Accept" 
         location="bottom" 
         cookieName="EarnathonCookie" 
         expires={999} 
         hideOnAccept={true}

         style={{
            background: "#1178c1"
          }}

         buttonStyle={{
            background: "#fff",
            color: "#000",
            fontSize: '20px',
            borderRadius: '5px',
            fontWeight: 'bold'
          }}
         //overlay
      >
        Earnathon.com uses cookies to ensure you get the best experience on our website <a href="https://docs.google.com/document/d/1kRJ5X3ucL6034f3MSAp13wrBPxB0jFMsHykKJFz4z88/edit?usp=sharing" target="_blank" style={{ color: '#04b168' }}>Learn more</a>.
        Confirm your consent to the use of cookies.
      </CookieConsent>
    </>
  );
  export default App;