//--------------------------|| MODULE DEPENDENCIES ||-------------------------------//

import React, { 
  useState, 
  Suspense,
  useEffect,
  lazy
} from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
  withRouter
} from "react-router-dom";

import 'antd/dist/antd.dark.min.css';
import './App.css';
import 'babel-polyfill'
import './assets/styles/vendor.bundle.css';
import './assets/css/style.css';
import './assets/css/azalea.css';
import './assets/css/main.css';

import {message, messages } from "antd";


//--------------------------||      USER DEFINED COMPONENTS     ||-------------------------------//

import routes                from "./routes";
import { GlobalProvider }    from "./context/provider";
import { maintainance }      from "./config/";
import isAuthenticated       from "./utils/isAuthenticated";
import RefreshToken          from "./utils/refreshtoken";
import UserLeaveConfirmation from "./components/UserLeaveConfirmation";
import MyLoader              from './components/shared/loaders/';
const MaintainanceFoundComponent  = lazy(() => import("./components/Maintainance"));

import CookieConsent  from './components/CookieConsent/';

import jwt_decode from 'jwt-decode';

import ReactGA from 'react-ga';

import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

//
const trackingId = "G-2VRJ8NL6H6"; // Replace with your Google Analytics tracking ID

ReactGA.initialize(trackingId);
ReactGA.set({
  userId: localStorage.userData ? JSON.stringify(localStorage.userData).username : null
  // any data that is relevant to the user session
  // that you would like to track with google analytics
});

// Initialize google analytics page view tracking
history.listen(location => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});
//Event measurement
// ReactGA.event({
//   category: "Sign Up",
//   action: "User pressed the big blue sign up button",
// });


const expireJwt = (history) => {

    if(isAuthenticated()){

        let token =  localStorage.token;

        //console.log(token);

        let jwt = jwt_decode(token, {headers: true});

        let current_time = Date.now().valueOf();

        // const jwtExp = jwt.exp - current_time ;

        // if(jwtExp < 1500 && jwtExp > 0){

        // }

        if(jwt.exp < current_time){

            localStorage.removeItem('token');

            history.push("/login");
            message.open({
              type: 'error',
              content: 'Your Token Session Is Expired Please Sign In Again',
              duration: 5
            })

            return false;
        }else{
          // initCommunication();
          window.parent.postMessage({
            type: 'authToken',
            value: localStorage.token}, '*')
        }

    }
}
const appVersion = 'v1.0.4';

const forceLogout = () => {
  if(localStorage.getItem('v')){
    localStorage.clear();
  }
  // if(localStorage.getItem('v') !== appVersion){
  //   localStorage.clear();
  // }
}

const setVersion = (version) => {
  localStorage.setItem('v', JSON.stringify(version))
};


/**
 * 
 * @param {*} route 
 */
const RenderRoute = (route) => {
  const history = useHistory();
  expireJwt(history);
  forceLogout();
  // setVersion(appVersion);

  document.title = route.title || "Earnathon";
  if (route.needsAuth && !isAuthenticated() && route.needsAuth !== 'static') {
    history.push("/login");
  }else if(!route.needsAuth && isAuthenticated() && route.needsAuth !== 'static'){
    history.push("/home");
  }

  return (
    <Route
      exact
      path={route.path}
      render={(props) => <route.component route={history} {...props} />}
    />
  );
};

function App() {
  const [confirmOpen, setConfirmOpen] = useState(true);

  return (
    
      <Suspense  fallback={<MyLoader />}>
        <Router history={history} onUpdate={() => window.scrollTo(0, 0)}>
          <GlobalProvider>
            <RefreshToken/>
            <Switch>
              { !maintainance ? routes.map((route, index) => (
                <RenderRoute exact {...route} key={route.path} />
              )) : <Route path="*"
              render={(props) => <MaintainanceFoundComponent route={history} {...props} />} /> }
            </Switch>
            <CookieConsent/>
            </GlobalProvider>
        </Router>

      </Suspense>
    
  );
}

export default App;
