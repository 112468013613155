import React, { 
    createContext, 
    useReducer, 
    Children 
} from "react";
import auth                from "./reducers/auth";
import courses             from "./reducers/course";
import lessons             from "./reducers/lesson";
import quiz                from "./reducers/quiz";
import crypto              from "./reducers/crypto";
import user                from "./reducers/user";
import app                 from "./reducers/app";
import marketStats         from "./reducers/marketStats";

import authInitialState    from "./initialStates/authInitialState";
import coursesInitialState from "./initialStates/coursesInitialState";
import lessonsInitialState from "./initialStates/lessonsInitialState";
import quizInitialState    from "./initialStates/quizInitialState";
import cryptoInitialState  from "./initialStates/cryptoInitialState";
import userInitialState    from "./initialStates/userInitialState";
import appInitialState     from "./initialStates/appInitialState";
import marketStatsInitialState     from "./initialStates/appInitialState";

import {
  useHistory
} from "react-router-dom";

export const GlobalContext = createContext({});

export const GlobalProvider = ({ children }) => {
  let history = useHistory();
  const [authState, authDispatch] = useReducer(auth, authInitialState);
  const [coursesState, coursesDispatch] = useReducer(courses, coursesInitialState);
  const [lessonsState, lessonsDispatch] = useReducer(lessons, lessonsInitialState);
  const [quizState, quizDispatch] = useReducer(quiz, quizInitialState);
  const [currencyState, currencyDispatch] = useReducer(crypto, cryptoInitialState);
  const [userState, userDispatch] = useReducer(user, userInitialState);
  const [appState, appDispatch] = useReducer(app, appInitialState);
  const [marketStatsState, marketStatsDispatch] = useReducer(marketStats, marketStatsInitialState);
  return (
    <GlobalContext.Provider
      value={{
        history,
        authState,
        authDispatch,
        coursesState,
        coursesDispatch,
        lessonsState,
        lessonsDispatch,
        quizState,
        quizDispatch,
        currencyState,
        currencyDispatch,
        userState,
        userDispatch,
        appState, 
        appDispatch,
        marketStatsState,
        marketStatsDispatch
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
