import {
    MARKET_STATS_ERROR,
    MARKET_STATS_SUCCESS,
    MARKET_STATS_LOADING
} from "../../constants/actionTypes";
  
  const marketStats = (state, { payload, type }) => {
    switch (type) {
      case MARKET_STATS_LOADING:
        return {
          ...state,
          stats: {
            ...state.stats,
            statsLoading: true
        }
        };
  
      case MARKET_STATS_SUCCESS:
        return {
            ...state,
            stats: {
                ...state.stats,
                statsLoading: false,
                statsData: payload
            }
          };
      
      case MARKET_STATS_ERROR:
        return {
            ...state,
            stats: {
                ...state.stats,
                statsLoading: false,
                statsError: payload
            }
          };

      default:
        return state;
    }
  };
  
  export default marketStats;
  