import {
    REGISTER_LOADING,
    REGISTER_SUCCESS,
    KYC_SUCCESS,
    REGISTER_ERROR,
    LOGIN_LOADING,
    LOGIN_SUCCESS,
    LOGIN_ERROR,
    FORGOTPASS_LOADING,
    FORGOTPASS_SUCCESS,
    FORGOTPASS_ERROR,
    UPDATE_LOADING,
    UPDATE_SUCCESS,
    UPDATE_ERROR,
    LOGOUT_USER,
    VERIFIED
} from "../../constants/actionTypes";
  
  const auth = (state, { payload, type }) => {
    switch (type) {
      case UPDATE_LOADING:
      case REGISTER_LOADING:
      case FORGOTPASS_LOADING:
      case LOGIN_LOADING:
        return {
          ...state,
          auth: {
            ...state.auth,
            error: false,
            success: false,
            loading: true,
          },
        };
  
      case REGISTER_SUCCESS:
      case FORGOTPASS_SUCCESS:
      case LOGIN_SUCCESS:
        return {
          ...state,
          auth: {
            ...state.auth,
            loading: false,
            data: payload,
            kyc: null,
            token: payload.token || null
          },
        };

      case KYC_SUCCESS:
        return {
          ...state,
          auth: {
            ...state.auth,
            loading: false,
            kyc: payload,
            token: true
          },
        };

      case VERIFIED:
        return {
          ...state,
          auth: {
            ...state.auth,
            loading: false
          },
        };

      case UPDATE_SUCCESS:
      return {
        ...state,
        auth: {
          ...state.auth,
          success: payload,
          loading: false
        },
      };
      
      case UPDATE_ERROR:
      case REGISTER_ERROR:
      case FORGOTPASS_ERROR:
      case LOGIN_ERROR:
        return {
          ...state,
          auth: {
            ...state.auth,
            loading: false,
            error: payload,
            kyc: null,
          },
        };

      case LOGOUT_USER:
        return {
          ...state,
          auth: {
            ...state.auth,
            data: null,
            token: false
          },
        };
      default:
        return state;
    }
  };
  
  export default auth;
  