import axios from "axios";
import jwt from "jsonwebtoken";
import AES from "crypto-js/aes";
import { baseURL } from "../constants/api";
const { REACT_APP_X_TOKEN, REACT_APP_SECRET_TOKEN } = process.env;

export default (history = null) => {
  const signXToken = jwt.sign({ time: Date.now() }, REACT_APP_X_TOKEN, {
    expiresIn: "10s",
  });
  const encryptAES = AES.encrypt(
    `${Date.now()}`,
    REACT_APP_SECRET_TOKEN
  ).toString();
  let headers = {
    // "origin": 'https://earnathon.com',
    // "Access-Control-Allow-Origin": "https://earnathon.com",
    "x-token": signXToken,
    "secret-token": encryptAES,
  };

  if (localStorage.token) {
    headers.Authorization = `Bearer ${localStorage.token}`;
  }

  // if(localStorage.captchaToken){
  //   headers.captchaToken = localStorage.captchaToken;
  // }

  const axiosInstance = axios.create({
    baseURL: baseURL,
    headers,
  });

  axiosInstance.interceptors.response.use(
    (response) =>
      new Promise((resolve, reject) => {
        // if(localStorage.captchaToken){
        //   localStorage.removeItem('captchaToken');
        // }
        resolve(response);
      }),
    (error) => {
      if (!error.response) {
        return new Promise((resolve, reject) => {
          reject(error);
        });
      }

      if (error.response.status === 403) {
        localStorage.removeItem("token");

        if (history) {
          history.push("/login");
        } else {
          window.location = "/login";
        }
      } else {
        return new Promise((resolve, reject) => {
          reject(error);
        });
      }
    }
  );

  return axiosInstance;
};
